@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');
* {
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: 'Outfit', sans-serif;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

::-webkit-scrollbar {
  display: none;
}


:root {
  --color-web-root: #f9f9f9;
  --color-text-Primary: #1d1d1d;
  --color-text-Secondary: #353535;
  --color-button-Primary: #484540;
  --color-button-Primary-hover: #33302d;
  --color-text-Tertiary: #666666;
  --color-text-Disable: #ababab;

  --color-bg-Secondary: #f1f1f1;
  --color-stroke-Primary: #d9d9d9;
  --color-stroke-Outline: #dfe4ec;

  --color-Bg-Light: #eef9ff;

  --color-white: #ffffff;
  --color-greenSuccess: #23b339;
}

.Phone input::placeholder {
  color: #9a9a9a !important;
}

.PhoneInput {
  width: 100% !important;
}

.PhoneInputCountry {
  display: flex !important;
  border: 1px solid #dfe4ec !important;
  padding: 16px 15px !important;
  border-radius: 6px !important;
}

.not-error .PhoneInputInput {
  border-top-right-radius: 30px !important;
  border-bottom-right-radius: 30px !important;
  border: 1px solid #dfe4ec;
  padding: 12px 12px !important;
  font-size: 16px !important;
  font-family: 'Outfit', sans-serif;
  color: #1d1d1d !important;
  font-weight: 400;
  width: 100% !important;
  outline: 0px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
.error-class .PhoneInputInput {
  border-top-right-radius: 30px !important;
  border-bottom-right-radius: 30px !important;
  border: 1px solid #ff0000 !important;
  padding: 12px 12px !important;
  font-size: 16px !important;
  font-family: 'Outfit', sans-serif;
  color: #1d1d1d !important;
  font-weight: 600;
  width: 100% !important;
  outline: 0px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
.not-error-isAuth .PhoneInputInput {
  border-top-right-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
  border: 1px solid #dfe4ec;
  padding: 12px 12px !important;
  font-size: 14px !important;
  font-family: 'Outfit', sans-serif;
  color: #1d1d1d !important;
  font-weight: 400;
  width: 100% !important;
  outline: 0px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
.error-class-isAuth .PhoneInputInput {
  border-top-right-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
  border: 1px solid #ff0000 !important;
  padding: 12px 12px !important;
  font-size: 16px !important;
  font-family: 'Outfit', sans-serif;
  color: #1d1d1d !important;
  font-weight: 600;
  width: 100% !important;
  outline: 0px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.PhoneInputCountryIcon--border {
  background-color: transparent !important;
  box-shadow: none !important;
  width: 27px !important;
}

.PhoneInputCountrySelectArrow {
  width: 8px !important;
  height: 8px !important;
  opacity: 1 !important;
  border-right: 1px solid !important;
  border-bottom: 1px solid !important;
  border-color: black !important;
  margin-left: 8px !important;
  margin-bottom: 4px !important;
}
.PhoneInputCountryFlag {
  display: none;
}
.captchaClass {
  display: flex !important;
}
#canv {
  width: 200px;
}
.hideScrollbar::-webkit-scrollbar {
  display: none;
}

/* .option {
  display: inline-block;
  text-decoration: none;
}

.option:after {
  display:block;
  content: '';
  width: inherit;
  border-bottom: solid 3px var(--color-button-Primary);  
  transform: scaleX(0);  
  transition: transform 250ms ease-in-out;
}

.option:hover:after { transform: scaleX(1); }  */

/* LOADER */
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
.animate-spinner {
  animation: spinner 1s linear infinite;
}

.border-inner {
  -webkit-box-shadow: inset 0px 0px 0px 1px #484540;
  -moz-box-shadow: inset 0px 0px 0px 1px #484540;
  box-shadow: inset 0px 0px 0px 1px #484540;
}

.mySwiperPlan .swiper-pagination {
  text-align: center !important;
  bottom: -5px !important;
}
.mySwiperPlan .swiper-wrapper {
  padding-bottom: 10px !important;
}

/* FAQ */
.answerSection {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: opacity 0.2s ease, max-height 0.4s ease; /* Adjust the duration and timing function as needed */
}

/* Open state */
.answerSection.open {
  opacity: 1;
  max-height: 200px; /* Adjust the value as needed */
}
/* SWITCH */
.cookie-switch {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

/* legal pages */
.underlineSpan {
  color: #1d1d1d !important;
  text-decoration: underline;
  cursor: pointer;
}
.boldfont {
  font-weight: 600;
  color: #353535;
}

/* Legal BOX SHADOW */
.legal-shadow {
  box-shadow: 0px 8px 40px 0px #19224c1a;
}
.mobilemenu-shadow {
  box-shadow: 0px 4px 30px 0px #0000000d;
}

#work-container #product-work-text {
  height: 1080px;
}

.dark-font {
  font-weight: 600;
  color: var(--color-text-Primary);
}
.dark-font1 {
  font-weight: 550;
  color: var(--color-text-Primary);
}

.path-animation {
  animation: draw-line 8s forwards; /* Adjust time as needed */
}

.payment-shadow {
  box-shadow: 0px 20px 30px 0px #0000001a;
}
.clip-path-class {
  clip-path: polygon(0 10%, 100% 0%, 100% 90%, 0% 100%);
}
.clip-path-class-upper {
  clip-path: polygon(0 10%, 100% 0%, 100% 100%, 0% 100%);
}
.my-custom-captcha {
  height: 43px;
  width: 360px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('../src/assets/images/HomePage/CapchaBack.png');
  border-radius: 16px;
}
@keyframes draw-line {
  from {
    stroke-dashoffset: 1040; /* Match this to the total length of your path */
  }
  to {
    stroke-dashoffset: 0;
  }
}

/* Product PaymentLine Animation  */
@keyframes drawLine {
  from {
    stroke-dashoffset: 3500;
  }
  to {
    stroke-dashoffset: 0;
  }
}

.animatedLine {
  animation: drawLine 8s forwards;
}

.Toastify__toast{
  min-height: 44px !important;
}
.Toastify__toast-container{
  width: max-content !important;
}
.Toastify__toast-theme--light{
  background: #BDE9C4 !important;
}
.Toastify__close-button{
  display: none !important;
}
@media (max-width: 1920px) {
  #work-container #product-work-text {
    height: 100vh;
  }
}

@media (max-width: 1300px) {
  #work-container #product-work-text {
    max-width: 980px;
  }
  .clip-path-class {
    clip-path: polygon(0 8%, 100% 0%, 100% 92%, 0% 100%);
  }
  .clip-path-class-upper {
    clip-path: polygon(0 8%, 100% 0%, 100% 100%, 0% 100%);
  }
}

@media (max-width: 980px) {
  #work-container #product-work-text {
    padding-left: 40px;
    padding-right: 40px;
  }
  .clip-path-class {
    clip-path: polygon(0 6%, 100% 0%, 100% 94%, 0% 100%);
  }
  .clip-path-class-upper {
    clip-path: polygon(0 6%, 100% 0%, 100% 100%, 0% 100%);
  }
}

@media (max-width: 600px) {
  .answerSection.open {
    max-height: 350px;
  }
  .clip-path-class {
    clip-path: polygon(0 3%, 100% 0%, 100% 97%, 0% 100%);
  }
  .clip-path-class-upper {
    clip-path: polygon(0 2.5%, 100% 0%, 100% 100%, 0% 100%);
  }
  .my-custom-captcha {
    width: 200px;
    height: 40px;
  }
}
@media (max-width: 500px) {
  .Toastify__toast-container{
    left: 21% !important;
    top: 3% !important;
  }
}
@media (max-width: 380px) {
  .Toastify__toast-body{
    padding: 0 !important;
  }
  .Toastify__toast-container{
    left: 19% !important;
  }
}
@media (max-width: 350px) {
  .Toastify__toast-container{
    left: 17% !important;
  }
  .clip-path-class-upper {
    clip-path: polygon(0 2%, 100% 0%, 100% 100%, 0% 100%);
  }
}
@media (max-width: 330px) {
  /* .Toastify__toast-container{
    left: 5% !important;
  } */
}

/* TAILWIND CSS COMMON CLASS */
@layer components {
  .FLEX_CENTER {
    @apply flex items-center justify-center;
  }
  .Text16NormalF {
    @apply text-textSmall font-OutfitFont font-normal text-TertiaryT cursor-pointer hover:text-PrimaryT;
  }
  .Text16Normal {
    @apply text-textSmall font-OutfitFont font-normal text-TertiaryT;
  }
  .Text24Footer {
    @apply text-text2Xl font-OutfitFont text-PrimaryT font-semibold max_md:text-textXl max_sm:text-textMedium;
  }
  .text40class {
    @apply text-subHeadingW text-ButtonP font-bold max_xl:text-[38px] font-OutfitFont max_md:text-[32px] max_sm:text-text2Xl 
    leading-[60px] max_md:leading-[45px] max_sm:leading-[35px];
  }
  .headingText {
    @apply text-headingW font-OutfitFont font-semibold text-PrimaryT leading-[88px] max_md:text-subHeadingW max_md:leading-[50px] max_sm:text-text3Xl max_sm:leading-[37px];
  }
  .Text24 {
    @apply text-text2Xl font-OutfitFont font-normal leading-[34px] text-TertiaryT max_md:text-textMedium max_md:leading-[28px] max_sm:text-textSmall max_sm:leading-6;
  }
  .paragraphText {
    @apply text-text2Xl font-OutfitFont font-normal max_md:text-textMedium leading-[34px] max_sm:text-textSmall max_md:leading-[28px] max_sm:leading-[24px];
  }
  .subHeading {
    @apply text-subHeadingW font-OutfitFont font-semibold text-PrimaryT leading-[50px] max_md:text-textLarge max_md:leading-[40px] max_sm:text-text2Xl max_sm:leading-[30px];
  }
  .Text20Normal {
    @apply text-textMedium font-OutfitFont font-normal text-TertiaryT leading-[25px] max_sm:text-textSmall max_sm:leading-[22px];
  }
  .Text30 {
    @apply text-text3Xl font-OutfitFont font-semibold leading-[37px] text-PrimaryT max_sm:text-text2Xl max_sm:leading-[30px];
  }
  .Text50 {
    @apply text-[50px] leading-[63px] font-OutfitFont font-semibold text-PrimaryT max_md:text-subHeadingW max_md:leading-[50px] max_sm:text-text2Xl max_sm:leading-[30px];
  }
  .Text18Price {
    @apply text-textNormal font-OutfitFont font-semibold text-TertiaryT;
  }
  .smallText16Normal {
    @apply text-textSmall font-OutfitFont font-normal text-TertiaryT;
  }
  .text22 {
    @apply text-textXl font-OutfitFont font-semibold text-PrimaryT max_sm:text-textMedium;
  }
  .smallText18L {
    @apply text-textNormal font-OutfitFont;
  }
  .marginTop150 {
    @apply mt-[150px] max_md:mt-[100px] max_sm:mt-[60px];
  }
  .marginBottom150 {
    @apply mb-[150px] max_md:mb-[100px] max_sm:mb-[60px];
  }
  .labelClass {
    @apply text-textExtraSmall font-OutfitFont font-semibold text-PrimaryT text-start w-full uppercase max_sm:text-[12px] mb-[3px];
  }
}
